
import {Table,Nav,Col,Tab,Row} from 'react-bootstrap'
function Acenter() {


    return(

<div>

<Table striped bordered hover>
  <thead>
    <tr>
      <th>#</th>
      <th>Developer List </th>
      <th>Email</th>
      <th>Developemnt Platform</th>
      <th>Credit</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1</td>
      <td>Faheem Ali</td>
      <td>Faheem.19@outlook.com</td>
      <td>React-js</td>
      <td>Apple Servers</td>
    </tr>
    <tr>
      <td>2</td>
      <td>Muhammad Shahroz Saddiqui </td>
      <td>Mshahrozsaddiqui@gamil.com</td>
      <td>Node-Js</td>
      <td>React-Librairy's</td>
    </tr>

    
  </tbody>
</Table>


<div style={{marginTop:80}}>

<center>

<h1>More About-Us </h1>
</center>

</div>


<div>

<Tab.Container id="left-tabs-example" defaultActiveKey="first">
  <Row>
    <Col sm={3}>
      <Nav variant="pills" className="flex-column">
        <Nav.Item>
          <Nav.Link eventKey="first"><strong> About DataBase</strong></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="second"><strong> Our Goal</strong></Nav.Link>
        </Nav.Item>
      </Nav>
    </Col>
    <Col sm={9}>
      <Tab.Content>
        <Tab.Pane eventKey="first">
        <p> <a style={{fontSize:40}} >T</a>his website is 100% free for user's And the software copyrights Belongs to Apple.inc we dont have or own any of the iPSW and Beta Profile, Make sure that these are Apple Developer Beta's which consists of bugs, if you want less bugs then you can try the Apple Public Beta by simpling going to <a href="beta.apple.com"> beta.apple.com</a>  </p>
        </Tab.Pane>
        <Tab.Pane eventKey="second">
          <p> <a style={{fontSize:40}} >W</a>e are working hard to provide you new updates from Apple thanks to apple that they are providing these software for free to, developer's and to public  </p>
        </Tab.Pane>
      </Tab.Content>
    </Col>
  </Row>
</Tab.Container>



</div>
 
<div>

  
</div>
<div>

<p>  <center> <strong> Note :</strong> <a style={{color: 'red'}}>We Dont own any of the Profiles and IPSW's , All CopyRight goes to Apple  </a></center></p>

</div>

<div style={{marginBottom:30}}>

</div>

</div>

    )
    
}
export default Acenter