import Header from '../Header'
import Acenter from './Acenter'
import Footer from '../Footer'
import { Helmet } from 'react-helmet'
function About() {


    return(
<>
<Header/>

<Helmet>
 
<title>About US </title>
<meta name="description" content="Download Profile Beta Works 24/7 For You To Provide Free Software Updates of Apple iOS " data-react-helmet="true"/>
<meta name="keywords" content="Profile Beta , About Beta Profile, iOS 15 About Profile, Profile Update IOS 15 ,iOS Update Profiler" />

<meta property="og:url" content="https://beta.ioslift.com/about"/>
<meta property="og:type" content="website"/>
<meta property="og:title" content="About Beta Profile "/>
<meta property="og:description" content="Know More About iOS Beta Profile By Faheem Ali And Muhammad Shahroz Saqqiqui "/>
<meta property="og:image" content="http://beta.ioslift.com/img/og20.jpg"/>


<meta name="twitter:card" content="summary_large_image"/>
<meta property="twitter:domain" content="beta.ioslift.com/about"/>
<meta property="twitter:url" content="https://beta.ioslift.com"/>
<meta name="twitter:title" content="Profile Beta "/>
<meta name="twitter:description" content="Know More About iOS Beta Profile By Faheem Ali and Muhammad Shahroz Saddiqui "/>
<meta name="twitter:image" content="http://beta.ioslift.com/img/og20.jpg" />


       
        </Helmet>
        <div style={{backgroundColor: '#F8F8F8',textAlign:'center'}}>

        <h1 style={{fontSize:100}}> About US </h1>
        <h2> Our Server </h2>
        </div>

        <Acenter/>
        <Footer/>
        </>
    )
    
}
export default About